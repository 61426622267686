import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
  HashLocationStrategy,
  LocationStrategy,
  registerLocaleData,
} from '@angular/common';
import localeSv from '@angular/common/locales/sv';
import localeNo from '@angular/common/locales/nb';
import { SharedModule } from './shared/shared.module';
import { EnvironmentService } from './core/services/environment.service';
import { GlobalErrorHandler } from './core/error-handling/global-error-handler';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { MyBookingModule } from './pages/my-booking/my-booking.module';
import { TextMaskModule } from 'angular2-text-mask';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StyleManagerService } from './core/services/style-manager.service';
import * as moment from 'moment';
import { NotFoundPageComponent } from './pages/my-booking/not-found-page/not-found-page.component';
import { MyBookingPageModule } from './pages/my-booking/my-booking-page/my-booking-page.module';
import { HeaderComponent } from './header/header.component';
import { NavigationService } from './core/services/navigation.service';
import {ConfigurationModule} from './core/configuration/configuration.module';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  let assetsUrl = './assets';
  if (globalAppConfig.assetsUrl) {
    assetsUrl = globalAppConfig.assetsUrl;
  }
  return new TranslateHttpLoader(
    http,
    `${assetsUrl}/i18n/`,
    '.json?' + new Date().getTime()
  );
}

export class DynamicLocaleId extends String {
  constructor(protected service: TranslateService) {
    super('');
  }

  toString(): string {
    return this.service.currentLang;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    ErrorPageComponent,
    NotFoundPageComponent,
    HeaderComponent,
  ],
  imports: [
    ConfigurationModule,
    MyBookingPageModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MyBookingModule,
    TextMaskModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    SharedModule,
  ],
  providers: [
    NavigationService,
    { provide: EnvironmentService, useFactory: environmentService },
    { provide: LOCALE_ID, useClass: DynamicLocaleId, deps: [TranslateService] },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(translate: TranslateService, private env: EnvironmentService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang(this.env.languageCode);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use(this.env.languageCode);

    // enables locales that will be used to set provider LOCALE_ID which is dynamically updated from DynamicLocaleId
    // later used in pipes
    registerLocaleData(localeSv, this.env.languageCodeSweden);
    registerLocaleData(localeNo, this.env.languageCodeNorway);

    moment.locale(this.env.languageCode);

    StyleManagerService.loadScript(this.env.themeCss);
  }
}

export function environmentService(): EnvironmentService {
  const environment = new EnvironmentService(globalAppConfig);
  console.log('Feature Rebook', environment.hasFeature('Rebook'), globalAppConfig.featureRebook);
  return environment;
}
