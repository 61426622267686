import {Inject, Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {GlobalAppConfig} from './global-app-config';
import {GLOBAL_APP_CONFIG} from './tokens';

@Injectable()
export class GlobalConfigService implements Omit<GlobalAppConfig, 'testCases'> {
  apiEndpoint?: string;
  apiToken?: string;
  countryCode?: string;
  envLabel?: string;
  debug?: boolean;
  featureExtraGlassTreatment?: boolean;
  themeCss?: string;
  homepageUrl?: string;
  googleMapsUrl?: string;
  assetsUrl?: string | undefined;
  customerServicePhoneNumber?: string | undefined;
  serviceConfig?: {
    packages: { key: string; price: number; services: string[] }[];
    services: { key: string; price: number; regularPrice: number; }[];
  };

  constructor(
    @Inject(GLOBAL_APP_CONFIG) private globalConfig: GlobalAppConfig,
    private activatedRoute: ActivatedRoute
  ) {
    return new Proxy(this, this);
  }

  public get(target: any, prop: string) {
    return target.resolve(prop);
  }

  private resolve(field: string): any {
    const testCase = this.activatedRoute.snapshot.queryParams.testCase;

    if (!testCase || !this.globalConfig.testCases) {
      return (this.globalConfig as any)[field];
    }

    const testConfig = this.globalConfig.testCases.find(
      (tc) => tc.key === testCase
    );

    if (!testConfig) {
      return (this.globalConfig as any)[field];
    }

    if ((testConfig as any)[field]) {
      return (testConfig as any)[field];
    }

    return (this.globalConfig as any)[field];
  }
}
